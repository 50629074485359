import { Link } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { useIsLoggedIn } from "./useIsLoggedIn";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navigation() {
  const isLoggedIn = useIsLoggedIn();

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-light sticky-top navbar-expand-lg shadow-sm bg-light rounded">
      <Container>
        <Navbar.Brand as={Link} to="/"><img width="154px" height="39px" src='/img/logo23.png' alt="AnyIntelli" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mb-2 mb-lg-0">
            <Nav.Link as={Link} to="/elevate" href="/elevate">Elevate</Nav.Link>
            <Nav.Link as={Link} to="/draft" href="/draft">Draft</Nav.Link>
            <Nav.Link as={Link} to="/blueprints" href="/blueprints">Blueprints</Nav.Link>
            <Nav.Link as={Link} to="/ata" href="/ata">ATA</Nav.Link>
            <Nav.Link as={Link} to="/intro" href="/intro">O.M.L.E.T.</Nav.Link>
          </Nav>

          <Nav className="ms-auto mb-2 mb-lg-0">
            <Nav.Link as={Link} to="/practice" href="/practice">Practice</Nav.Link>
            <Nav.Link as={Link} to="/contact" href="/contact">Contact</Nav.Link>
            {!isLoggedIn() && <Nav.Link as={Link} to="/signin" href="/signin">Sign in</Nav.Link>}
            {isLoggedIn() && <NavDropdown title="My account" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/card" href="/card">Card</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/inbox" href="/inbox">Inbox</NavDropdown.Item> */}
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/signout" href="/signout" onClick={() => signOut()}>Sign out</NavDropdown.Item>
            </NavDropdown>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
