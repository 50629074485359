import Seo from "./Seo";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

function ATA() {

  return (
    <div className="container">
      <Seo title="ATA" />
      <h2>Technology Acquisitions</h2>

      <div className="row featurette text-center mb-3">

        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-premium1 shadow">
            <div className="card-body">
              <h4 className="card-title">Alternative lab</h4>
              <p>When SaaS technology is primary acquisition goal this is your way to get to BATNA.</p>
              <p>When you want to check team opinion, with tech option.</p>
              <p className="card-text">Double execution is better than double-checking. Evaluate moat of technology of interest.</p>

            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/intro/ata-alt">Request a quote</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#lab">Learn more</a>
            </div>

          </div>
        </div>


        <div className="col-md-4  mb-1">
          <div className="card h-100 bc-premium2 shadow">
            <div className="card-body">
              <h4 className="card-title">Flip a SaaS</h4>
              <p className="card-text">Like an old house it comes time when SaaS business does need a renovation.</p>
              <p>Optimization of operational frontiers for established services.</p>
              <p>Improvement of customer retention by bringing technology to modern standards.</p>

            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/intro/ata-flip">Request a quote</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#flip">Learn more</a>
            </div>
          </div>
        </div>





        <div className="col-md-4 text-dark mb-1">
          <div className="card h-100 bc-premium3 shadow">
            <div className="card-body">
              <h4 className="card-title">The Chassis</h4>
              <p className="card-text">Business executives can evaluate and prepare SaaS acquisitions outside of inert or overloaded processes.</p>
              <p>Grow startups within your enterprise using <Link to="/blueprints">startup blueprint</Link>.</p>
              <p>Continuous business modernization through modular technology acquisitions, and deprecations.</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-sm btn-success" to="/intro/ata-saas">Request a quote</Link> &nbsp;
              <a className="btn btn-sm btn-primary" href="#chassis">Learn more</a>
            </div>

          </div>
        </div>
      </div>

      <div className="row featurette">
        <div className="col-md-7">
          <img className="featurette-image img-fluid" src="/img/main/ata.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-5">
          <p>AnyIntelli Technology Acquisitions (ATA) program allows for turning software systems into integrated and resilient assets.</p>
          <p>As technology and business leader you constantly working on expanding palette of features and capabilities serving and providing to your customer.</p>
          <p>This program is meant to support dynamic technology executions. The vision in foundation of it is:</p>
          <ol>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377827604819968" target="_blank" rel="noreferrer">Strategy is all about starting and stopping in time</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377662588342273" target="_blank" rel="noreferrer">Virtual facilities define uniqueness of a business</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376351708958721" target="_blank" rel="noreferrer">Resiliency</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377752740704256" target="_blank" rel="noreferrer">More cycles you continue, better process you get</a></li>
          </ol>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-secondary shadow">
            <div className="card-header text-center">
              <p>Ideal for SMB (small and midsize business)</p>
            </div>

            <div className="card-body">
              <h4 className="card-title text-center" id="lab">Alternative lab</h4>
              <p><i className="bi bi-info" /> Before purchasing a SaaS allows for technology evaluation</p>
              <p><i className="bi bi-info" /> Create second tech option for an acquisition</p>
              <p><i className="bi bi-info" /> After purchasing of a SaaS allows for design of best incorporation strategy</p>
              <p><i className="bi bi-info" /> In certain cases, incorporation path includes renovation of an old technology, and white labeling it</p>
              <p><i className="bi bi-info" /> Or incorporating SaaS as part of integral palette of in-house web services</p>
            </div>


            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-md btn-success" to="/intro/ata-alt">Request a quote</Link>
            </div>
          </div>
        </div>


        <div className="col-md-4 mb-1">


          <div className="card h-100 bc-secondary shadow">
            <div className="card-header text-center">
              <p>Ideal for SMB (small and midsize business) </p>
            </div>
            <div className="card-body">
              <h4 className="card-title text-center" id="flip" >Flip a SaaS</h4>
              <p><i className="bi bi-info" /> Established systems do need renovation</p>
              <p><i className="bi bi-info" /> Optimize for better technology operations</p>
              <p><i className="bi bi-info" /> Remove considerable maintenance pressure from the software technology side</p>
              <p><i className="bi bi-info" /> Automation of existing processes which are  manual, or currently absent, or have considerable surface for human error</p>
              <p><i className="bi bi-check" /> Various forms of cooperation can be discussed as this is cornerstone interest of the practice</p>
            </div>
            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-md btn-success" to="/intro/ata-flip">Request a quote</Link>
            </div>
          </div>
        </div>



        <div className="col-md-4 mb-1">
          <div className="card h-100 bc-secondary shadow">
            <div className="card-header text-center">
              <p>Ideal for enterprise in general </p>
            </div>

            <div className="card-body">
              <h4 className="card-title text-center" id="chassis">The Chassis</h4>
              <p><i className="bi bi-info" />  Grow startups within your enterprise</p>

              <p><i className="bi bi-info" />  Optimize time to start and stop business line</p>
              <p><i className="bi bi-info" />  Offload creation of services into agile and isolated pipeline</p>
              <p><i className="bi bi-info" />  Experiment, groom, bring up to speed software technology, or abandon assets before they touch your perimeter</p>
              <p><i className="bi bi-info" />  Make sure there is operational framework (software solutions chassis) in place to allow such onboarding</p>

            </div>

            <div className="card-footer text-center bg-transparent border-0">
              <Link className="btn btn-md btn-success" to="/intro/ata-saas">Request a quote</Link>
            </div>

          </div>
        </div>

      </div>

    </div>

  );
}

export default ATA;
