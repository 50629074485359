import Seo from "./Seo";
import { Link } from "react-router-dom";

function Practice() {
  return (
    <div className="container">
      <Seo title="Technology practice" />
      <h2>Practice</h2>

      <div className="row featurette">

        <div className="col-md-4">
          <img className="featurette-image img-fluid" src="/img/main/practice-team.jpg" alt="Growth" width="800px" height="800px" />
        </div>

        <div className="col-md-4">
          <h5>Mission</h5>
          <p>Sustainable teams driven by resiliency and reliability of their systems.</p>

          <h5>Vision</h5>
          <ul>
            <li><a href="https://blog.anyintelli.com/2024/11/alpha-is-as-strong-as-omega-resilient.html" target="_blank" rel="noreferrer">Alpha is as strong as omega resilient</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/keep-technology-menu-short.html" target="_blank" rel="noreferrer">Keep technology menu short</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/go-places-with-each-failure.html" target="_blank" rel="noreferrer">Go places with each failure</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/virtual-facilities-define-uniqueness-of.html" target="_blank" rel="noreferrer">Virtual facilities define uniqueness of a business</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/more-cycles-you-continue-better-process.html" target="_blank" rel="noreferrer">More cycles you continue, better process you get</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/strategy-is-all-about-starting-and.html" target="_blank" rel="noreferrer">Strategy is all about starting and stopping in time</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/non-progredi-est-regredi.html" target="_blank" rel="noreferrer">“Non progredi est regredi”</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/software-development-evs-vulcans.html" target="_blank" rel="noreferrer">Execution is the key</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/technology-and-product-balance.html" target="_blank" rel="noreferrer">Technology and product balance</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/triangle-defense-customer-team-and-long.html" target="_blank" rel="noreferrer">"Triangle defense": customer, team, and long value</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/signature-of-artisan.html" target="_blank" rel="noreferrer">Signature of the artisan</a></li>
            {/* <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376351708958721" target="_blank" rel="noreferrer">Alpha is as strong as omega resilient</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376629912952833" target="_blank" rel="noreferrer">Keep technology menu short</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113376695117570051" target="_blank" rel="noreferrer">Go places with each failure</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377662588342273" target="_blank" rel="noreferrer">Virtual facilities define uniqueness of a business</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377752740704256" target="_blank" rel="noreferrer">More cycles you continue, better process you get</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377827604819968" target="_blank" rel="noreferrer">Strategy is all about starting and stopping in time</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7113377887428169728" target="_blank" rel="noreferrer">“Non progredi est regredi”</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7119891682396909568" target="_blank" rel="noreferrer">Execution is the key</a></li>
            <li><a href="https://www.linkedin.com/feed/update/urn:li:activity:7121266196234035200" target="_blank" rel="noreferrer">Technology and product balance</a></li>
            <li><a href="https://www.linkedin.com/posts/anyintelli_activity-7231774802544398336-Y-aW" target="_blank" rel="noreferrer">"Triangle defense": customer, team, and long value</a></li>
            <li><a href="https://www.linkedin.com/pulse/signature-artisan-anyintelli-t9ycf" target="_blank" rel="noreferrer">Signature of the artisan</a></li> */}
            <li><a href="https://blog.anyintelli.com/2024/11/decentralize.html" target="_blank" rel="noreferrer">Decentralize</a></li>
            <li><a href="https://blog.anyintelli.com/2024/11/leadership-as-service.html" target="_blank" rel="noreferrer">Leadership as a service</a></li>
          </ul>

          <h5>Focus & scope</h5>
          <p>Main focus is growing software development technology and teams specialized on SaaS leveraging cloud.</p>

          <h5>Themes</h5>

          <h6>Meaningful and productive career</h6>
          <p><Link to="/elevate">Elevate</Link> is focused on setting good incentives and meaningful productive career for seasoned professionals through sustainable development.</p>

          <h6>Beyond hiring</h6>
          <p><Link to="/draft">Draft</Link> is focused on forming the teams. <b>Teams where ideas grow</b>.</p>

          <h6>Produce</h6>
          <p><Link to="/blueprints">Blueprints</Link> are for founders, startups and companies interested in improving resiliency and reliability of their technologies.</p>
          <p><Link to="/ata">ATA</Link> is for established products and enterprise customers interested in growing a startup for their needs.</p>
        </div>

        <div className="col-md-4">
        <img className="featurette-image img-fluid" src="/img/main/tttr.png" alt="Team, Technology, Resilience, Transactions." width="600px" height="600px" />
        </div>



      </div>
    </div>
  );
}

export default Practice;
