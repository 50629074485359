import Seo from "./Seo";
import { Link } from "react-router-dom";
export function MailingAddress() {
  return (<div className="alert">
    AnyIntelli, Inc.<br />
    80 Broad Street, Floor 5<br />
    New York, NY 10004
  </div>)
}
function Contact() {

  return (
    <div className="container">
      <Seo title="Contact us" description='AnyIntelli contacts: address, email, and phone' keywords='AnyIntelli contact'  />
      <h2>Contact information</h2>

      <div className="row text-center">
        <div className="col-md-4">
          <h3>Mailing address</h3>
          <MailingAddress />
        </div>

        <div className="col-md-4">
        <h3>Intro & Contact</h3>
          <div className="alert">
            Follow O.M.L.E.T. process to <Link className="btn btn-sm btn-success" to="/intro">Request a quote</Link> personalized for your business.
          </div>
        </div>

        <div className="col-md-4">
          <h3>Email address</h3>
          <div className="alert">
            <a href="mailto:hello@anyintelli.com">hello@anyintelli.com</a>
          </div>

          <h3>Phone in NYC</h3>
          <div className="alert">
            +1 (917) 740-3691
          </div>

          {/* <h3>Social networks</h3>
              <div className="alert">
              <a target="_blank" href="https://www.facebook.com/AnyIntelli">Facebook Page</a> | <a target="_blank" href="https://twitter.com/AnyIntelli">Twitter Page</a>
              </div> */}
        </div>
      </div>

      <h2>Map</h2>
      <iframe title="our location" width="100%" height="350" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.622459990741!2d-74.01187689999999!3d40.7043118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a96dbf04bd%3A0xe9a8316589ea202f!2sAnyIntelli!5e0!3m2!1sen!2sus!4v1739150382311!5m2!1sen!2sus" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
}

export default Contact;
